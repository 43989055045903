import React from 'react';
import { FormattedMessage } from 'react-intl';
import ctaImg from './assets/images/cta.svg';

import CTAButton from '../../../CTAButton/CTAButton';
import SalesButton from '../../../SalesButton/SalesButton';

const CajitaCTA: React.FC = ({ children }) => (
  <>
  <section className="py-layout-7">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-6">
          <img className="img-fluid" src={ctaImg} />
        </div>
        <div className="col-md-6">
          <h2 className="mt-7 mt-md-0">
            <FormattedMessage id="checkout.cta.title"/>
          </h2>
          <div className="button-responsive-row mt-7">
            <SalesButton big={true} positionTag="bottom">
              <FormattedMessage id="buttons.sales"/>
            </SalesButton>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div className="pre-footer-bg"/>
  </>
);

export default CajitaCTA;
