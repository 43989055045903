import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../../data/defaultFormat';

import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import paymentMethodsIcon from './assets/images/payment-methods-icon.svg';
import responsiveIcon from './assets/images/responsive-icon.svg';
import secureIcon from './assets/images/secure-icon.svg';

import codeImg from './assets/images/code.svg';
import secureImg from './assets/images/pci.svg';

import magentoImg from './assets/images/magento.svg';
import prestashopImg from './assets/images/prestashop.svg';
import vtexImg from './assets/images/vtex.svg';
import woocommerceImg from './assets/images/woocommerce.svg';

import improvementBgImg from './assets/images/improvement-bg.svg';
import improvementImg from './assets/images/improvement.svg';
import responsiveBg from './assets/images/responsive-bg.svg';

import './styles.scss';

const CajitaFeatures: React.FC = ({ children }) => {
  const { cajitaResponsiveImg, paymentMethodsImg } = useStaticQuery(
    graphql`
      query {
        cajitaResponsiveImg: file(relativePath: {eq: "cajita/cajitaresponsive.png"}) {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        paymentMethodsImg: file(relativePath: {eq: "cajita/paymentmethods.png"}) {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `
  );

  return (
    <>
      <section className="py-layout-5 cajita__responsive-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 order-2 order-lg-1 col-10 offset-1 offset-md-0">
              <Img fluid={cajitaResponsiveImg.childImageSharp.fluid} alt="Cajita Responsive devices" className="img-fluid mt-layout-3 mt-lg-0"/>
            </div>
            <div className="col-lg-5 offset-lg-1 order-1 order-lg-2">
              <img src={responsiveIcon} alt="Responsive icon"/>
              <h3 className="text-primary mt-5">
                <FormattedMessage id="checkout.features.feature_1.title"
                  values={defaultFormatValues}
                />
              </h3>
              <p className="lead mt-5">
                <FormattedMessage id="checkout.features.feature_1.subtitle"
                  values={defaultFormatValues}
                />
              </p>
            </div>
          </div>
        </div>

        <div className="cajita__responsive-section__bg" style={{backgroundImage: `url(${responsiveBg})`}} />
      </section>

      <section className="py-layout-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <img src={secureIcon} alt="Secure icon"/>
              <h3 className="text-primary mt-5">
                <FormattedMessage id="checkout.features.feature_2.title"
                  values={defaultFormatValues}
                />
              </h3>
              <p className="lead mt-5">
                <FormattedMessage id="checkout.features.feature_2.subtitle"
                  values={defaultFormatValues}
                />
              </p>
            </div>
            <div className="col-lg-5 offset-lg-2 col-10 offset-1 offset-md-0">
              <img src={secureImg} alt="PCI Compliance" className="img-fluid mt-layout-3 mt-lg-0"/>
            </div>
          </div>
        </div>
      </section>

      <section className="py-layout-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-10 offset-1 offset-md-0 order-2 order-lg-1">
              <Img fluid={paymentMethodsImg.childImageSharp.fluid} alt="Payment methods" className="img-fluid mt-layout-3 mt-lg-0"/>
            </div>
            <div className="col-lg-5 offset-lg-1 order-1 order-lg-2">
              <img src={paymentMethodsIcon} alt="Payment methods icon"/>
              <h3 className="text-primary mt-5">
                <FormattedMessage id="checkout.features.feature_3.title"
                  values={defaultFormatValues}
                />
              </h3>
              <p className="lead mt-5">
                <FormattedMessage id="checkout.features.feature_3.subtitle"
                  values={defaultFormatValues}
                />
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-layout-5 bg-light-gray-3">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-10 offset-1 offset-md-0">
              <img src={codeImg} className="img-fluid mb-layout-3 mb-lg-layout-5" alt="Custom metadata"/>
              <h2 className="text-primary">
                <FormattedMessage id="checkout.features.feature_4.title"
                  values={defaultFormatValues}
                />
              </h2>
              <p>
                <FormattedMessage id="checkout.features.feature_4.subtitle"
                  values={defaultFormatValues}
                />
              </p>
            </div>
            <div className="col-md-5 offset-md-2 col-10 offset-1 offset-md-0">
              <div className="cajita__integrations-grid mb-layout-3 mb-lg-layout-5 mt-layout-5 mt-lg-0">
                <div className="cajita__integrations-grid__item" >
                  <img src={prestashopImg} alt="Prestashop" />
                  Prestashop
                </div>
                <div className="cajita__integrations-grid__item bg-light" >
                  <img src={magentoImg} alt="Magento" />
                  Magento
                </div>
                <div className="cajita__integrations-grid__item bg-light" >
                  <img src={woocommerceImg} alt="Woocommerce" />
                  Woocommerce
                </div>
                {/* <div className="cajita__integrations-grid__item" >
                  <img src={vtexImg} alt="Vtex" />
                  VTEX
                </div> */}
              </div>
              <h2 className="text-primary">
                <FormattedMessage id="checkout.features.feature_5.title"
                  values={defaultFormatValues}
                />
              </h2>
              <p>
                <FormattedMessage id="checkout.features.feature_5.subtitle"
                  values={defaultFormatValues}
                />
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="cajita__improvement-section" style={{background: `url(${improvementBgImg}) no-repeat, #F7FAFC`}}>
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-md-6 order-2 order-md-1">
              <h2 className="text-light mt-5">
                <FormattedMessage id="checkout.features.feature_6.title"
                  values={defaultFormatValues}
                />
              </h2>
              <p className="text-light-gray-2">
                <FormattedMessage id="checkout.features.feature_6.subtitle"
                  values={defaultFormatValues}
                />
              </p>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <img src={improvementImg} alt="Constant improvement" className="img-fluid"/>
            </div>
          </div>
        </div>
      </section>
    </>
  )
};

export default CajitaFeatures;
