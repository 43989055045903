import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../../data/defaultFormat';

import SalesButton from '../../../SalesButton/SalesButton';

import bgImg from './assets/images/bg.svg';
import HeroImg from './assets/images/hero.inline.svg';

import './styles.scss';

const CajitaHero: React.FC = ({ children }) => (
  <section className="cajita-hero hero overflow-visible">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 order-2 order-lg-1">
          <h1 className="text-primary display-4">
            <FormattedMessage id="checkout.hero.title"/>
          </h1>
          <h2 className="text-primary">
            <FormattedMessage id="checkout.hero.title2"/>
          </h2>
          <p className="lead mt-5">
            <FormattedMessage id="checkout.hero.subtitle"
              values={defaultFormatValues}
            />
          </p>
          <div className="button-responsive-row mt-2">
            <SalesButton positionTag="top">
              <FormattedMessage id="buttons.sales"/>
            </SalesButton>
          </div>
        </div>
        <div className="col-lg-6 order-1 order-lg-2 text-md-center text-lg-right">
          <HeroImg className="img-fluid mb-layout-3 mb-lg-0"/>
        </div>
      </div>
    </div>

    <div className="hero-bg-right" style={{backgroundImage: `url(${bgImg})`}} />
  </section>
);

export default CajitaHero;
