import * as React from 'react';

import IndexLayout from '../../layouts';

import Footer from '../../components/Footer/Footer';
import SEO from '../../components/SEO/SEO';

import CajitaCTA from '../../components/Features/Cajita/CajitaCTA/CajitaCTA';
import CajitaFeatures from '../../components/Features/Cajita/CajitaFeatures/CajitaFeatures';
import CajitaHero from '../../components/Features/Cajita/CajitaHero/CajitaHero';
import { LocaleTypes } from '../../data/locales';

interface CajitaPageProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const CajitaPage: React.FC<CajitaPageProps> = ({ pathContext, location }) => {
  
  const content = () => (
    <>
      <SEO titleId="checkout.metaTitle" descriptionId="checkout.metaDescription" />
      <CajitaHero />
      <CajitaFeatures />
      <CajitaCTA />
      <Footer theme="primary" />
    </>
  );

  return <IndexLayout render={ content } locale={pathContext.localeCode} location={location} />
}

export default CajitaPage;
